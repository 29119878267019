import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './GuestForm.css'; 
import { getAuth, signInAnonymously } from 'firebase/auth';
import background from '../assets/trans-bg-flag.jpg';
import { useProfanityChecker } from 'glin-profanity';

// Ensure GUEST_ is the prefix for anonymous UserIDs and it has a random 3 digit number appended to it
const GuestForm = () => {
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const { result, checkText } = useProfanityChecker({ allLanguages: true, caseSensitive: false, wordBoundaries: true, customWords: ["asshole"] });

    const handleSubmit = async (e) => {
        await checkText(name);
        if (result?.containsProfanity) {
            window.alert(`We caught some profanities in that name! Please remove it.`);
            return;
        }
        e.preventDefault();
        const auth = getAuth();
        try {
            await signInAnonymously(auth);
            navigate('/chat');
        } catch (error) {
            console.error(error);
        }
    };
    
    return (
        <div className="wrapper">
            <div className="guest-form-container">
            <img className="background-image" alt="Trans Flag" src={background}></img>
            <h1>Enter as a Guest</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <button type="submit">Enter Chat</button>
            </form>
        </div>
        </div>
    );
}

export default GuestForm;
