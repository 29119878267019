import React, {useState} from 'react';
import {auth} from '../services/firebase';
import {
    browserSessionPersistence,
    createUserWithEmailAndPassword,
    setPersistence,
    signInWithEmailAndPassword,
    updateProfile
} from 'firebase/auth'; // Added updateProfile for saving username
import './LoginForm.css';
import background from '../assets/trans-bg-flag.jpg';
import {useProfanityChecker} from 'glin-profanity';

const LoginForm = () => {
    const [isSignUp, setIsSignUp] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState(''); // Added state for username
    const [error, setError] = useState('');
    const { result, checkText } = useProfanityChecker({ allLanguages: true, caseSensitive: false, wordBoundaries: true, customWords: ["asshole"] });

    const toggleForm = () => {
        setIsSignUp((prev) => !prev);
    };

    // Login with session persistence
    const loginWithSession = async () => {
        // if (auth.currentUser || browserSessionPersistence) {
        //     await setPersistence(auth, browserSessionPersistence);
        //     window.location.href = '/chat';
        // }
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            if (isSignUp) {
                // Sign up the user
                await checkText(username);
                if (result?.containsProfanity) {
                    window.alert(`We caught some profanities in that username! Please remove them before signing up.`);
                    return;
                }
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;

                // Update user profile to include the username
                await updateProfile(user, {
                    displayName: username // Save the username to the user's profile
                });

                await setPersistence(auth, browserSessionPersistence);

                alert('Account created successfully!');
            } else {
                // Log in the user
                await signInWithEmailAndPassword(auth, email, password);
                await setPersistence(auth, browserSessionPersistence);
                alert('Logged in successfully!');
            }

            // Redirect to chat after successful login or signup
            window.location.href = '/chat';
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="wrapper" onLoad={loginWithSession}>
            <div className="auth-form-container">
                <img className="background-image" alt="Trans Flag" src={background}></img>
                <h2> {isSignUp ? 'Sign Up' : 'Log In'} </h2>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleFormSubmit}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    {isSignUp && (
                        <input
                            type="text"
                            placeholder="Choose a username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    )}
                    <button type="submit">{isSignUp ? 'Sign Up' : 'Log In'}</button>
                </form>
                <p>
                    {isSignUp ? 'Already have an account?' : "Don't have an account?"}{' '}
                    <span onClick={toggleForm} className="toggle-link"> {isSignUp ? 'Log In' : 'Sign Up'} </span>
                </p>
            </div>
        </div>
    );
};

export default LoginForm;