import React from 'react';
import {updateProfile, signOut, browserSessionPersistence} from 'firebase/auth';
import { collection, updateDoc, query, orderBy, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../services/firebase'; // Firestore and Auth
import './Profile.css';

const Profile = () => {
    const user = auth.currentUser ? auth.currentUser : browserSessionPersistence;

    // Handle logout
    const handleLogout = async () => {
        try {
            await signOut(auth);
            window.location.href = '/'; // Redirect to the login page after logout
        } catch (error) {
            console.error('Error logging out: ', error);
        }
    };

    // Handle username change
    const handleUsernameChange = () => {
        try {
            if (!user) {
                alert('Please log in to change your username.');
                return;
            }
            if (!document.querySelector('.change-username input').value) {
                alert('Please enter a new username.');
                return;
            }
            if (document.querySelector('.change-username input').value === user.displayName) {
                alert('Please enter a different username.');
                return;
            }
            if (user.displayName.includes('GUEST_')) {
                alert('Guest users cannot change their username.');
                return;
            }
            if (document.querySelector('.change-username input').value.includes('GUEST_')) {
                alert('Usernames cannot contain "GUEST_".');
                return;
            }
            const newUsername = document.querySelector('.change-username input').value;
            updateProfile(user, {
                displayName: newUsername
            }).then(
                // Find every message in the db with the user's id and update the username
                onSnapshot(query(collection(db, 'messages'), orderBy('timestamp', 'asc')), (querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        if (doc.data().userID === user.uid) {
                            updateDoc(doc.ref, {
                                username: newUsername
                            });
                        }
                    });
                })
            );
            alert('Username updated successfully!');
        } catch (error) {
            console.error('Error updating username: ', error);
        }
    };

    return (
        <div className="profile-container">
            <h1>Your Profile</h1>

            <div className="profile-info">
                <p><strong>Email:</strong> {user?.email || 'Not Available'}</p>
                <p><strong>Username:</strong> {user?.displayName || 'Guest'}</p>
                <p><strong>User ID:</strong> {user?.uid}</p>
            </div>

            {user && user.displayName !== "Guest" && (
                <div className="change-username">
                    <p>Change Username:</p>
                    <input type="text" placeholder="Enter new username"/>
                    <button onClick={handleUsernameChange}>Save</button>
                </div>
            )}

            <button className="logout-button" onClick={handleLogout}>Log Out</button>
        </div>
    );
};

export default Profile;
