import React, { useState, useEffect } from 'react';
import { collection, doc, addDoc, deleteDoc, query, orderBy, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../services/firebase'; // Firestore and Auth
import './Chat.css';
// import { Link } from 'react-router-dom';
import { useProfanityChecker } from 'glin-profanity';

const adminUIDs = ["S1pQ3u8DNcNzKW9xls459zmOFi42", "9w3sHvsDMGec10mmtP2BeW4D9kv1"];

const Chat = () => {
    const [message, setMessage] = useState(''); // State for new message
    const [messages, setMessages] = useState([]); // State for fetched messages
    const { result, checkText } = useProfanityChecker({ allLanguages: true, caseSensitive: false, wordBoundaries: true, customWords: ["asshole"] });

    // Fetch messages from Firestore in real-time
    useEffect(() => {
        const q = query(collection(db, 'messages'), orderBy('timestamp', 'asc'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const msgs = [];
            querySnapshot.forEach((doc) => {
                msgs.push({ ...doc.data(), id: doc.id });
            });
            setMessages(msgs);
        });

        window.scroll(0, document.body.scrollHeight); // Scroll to the bottom of the chat when new messages are sent

        return () => unsubscribe(); // Unsubscribe from real-time updates when component unmounts
    }, []);

    // Check if the user using the adminUIDs array that sent a message is an admin and append ADMIN to the message 
    function isAdmin(uid) {
        return adminUIDs.includes(uid);
    }

    const handleSendMessage = async (e) => {
        // Allow words soon ["shit", "fuck", "dick", "cock", "ass", "asshole", "bitch", "crap", "fucked", "fucking", "fucked", "shitting"]
        await checkText(message);
        if (result?.containsProfanity) {
            window.alert(`We caught some profanities in that message! Please remove them before sending.`);
            return;
        }

        e.preventDefault();
        if (message.trim()) {
            const user = auth.currentUser;
            const displayName = user?.displayName || 'Guest'; // Use displayName or 'Guest'

            try {
                // Add message to Firestore with username and timestamp
                await addDoc(collection(db, 'messages'), {
                    userID: user.uid, // User ID
                    text: message,
                    username: displayName, // Username
                    timestamp: new Date(),
                });
                setMessage(''); // Clear the input field after sending
            } catch (error) {
                console.error('Error sending message: ', error);
            }
        }
    };

    // Create a function where admins can delete messages
    const handleDeleteMessage = async (id) => {
        if (!isAdmin(auth.currentUser.uid)) return alert('You are not an admin!');
        try {
            await deleteDoc(doc(db, 'messages', id));
        } catch (error) {
            console.error('Error deleting message: ', error);
        }
    }

    return (
        <div className="chat-container">
            {/* Message Display */}
            <div className="messages">
                {messages.map((msg) => (
                    <div key={msg.id} className="message">
                        <strong>{isAdmin(msg.userID) ? "[ADMIN] " + msg.username : msg.username}</strong>: {msg.text}
                        <p className="timestamp-text">Sent at {msg.timestamp?.toDate().toLocaleString({})}</p>
                        {isAdmin(auth.currentUser.uid) && <button className="delete-button" onClick={() => handleDeleteMessage(msg.id)}>Delete</button>}
                    </div>
                ))}
            </div>

            {/* Message Form */}
            <form className="message-form" onSubmit={handleSendMessage}>
                <input
                    type="text"
                    placeholder="Type a message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <button type="submit">Send</button>
            </form>

            {/* Link to Profile */}
            {/*<Link to="/profile" className="profile-link">Go to Profile</Link>*/}
        </div>
    );
};

export default Chat;
