import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Chat from './components/Chat';
import GuestForm from "./components/GuestForm";
import LoginForm from "./components/LoginForm";
import Profile from "./components/Profile"; // To be built later

const App = () => {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/signup" element={<LoginForm />} />
          <Route path="/guest" element={<GuestForm />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </Router>
  );
};

export default App;
