import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <div className="landing-container">
      <div className="split-box">
        <div className="login-section">
          <h2>Sign Up / Log In</h2>
          <p>Create an account or log in to access all features.</p>
          <Link to="/signup">
            <button className="action-button">Sign Up</button>
          </Link>
        </div>
        <div className="divider"></div>
        <div className="guest-section">
          <h2>Join as Guest</h2>
          <p>Enter your display name and start chatting instantly!</p>
          <Link to="/guest">
            <button className="action-button">Join as Guest</button>
          </Link>
        </div>
      </div>

      <div className="footer">
        <a href="https://www.ko-fi.com/acronical" target="_blank" rel="noreferrer">Donate to Me</a>
        <p>Made by Acronical</p>
        <a href="https://www.discord.gg/TKufucK4dh" target="_blank" rel="noreferrer">Join the Discord!</a>
      </div>
    </div>
  );
};

export default LandingPage;
